import React from 'react';
import classNames from 'classnames';
import { ITrainingRecordItem } from '@/api/history';
import { TrainingResult } from '@/modules/List/TrainingList/TraningResult';
import { getListeninQustionPath, getReadingQustionPath, getWritingQustionPath } from '@/modules/List/TrainingList/BasicQuestion';
import lessModule from './item.module.less';
export interface ITraingRecordItemProps {
  item: ITrainingRecordItem;
  onHomePage?: boolean;
}

export const TrainingRecordItem = React.memo<ITraingRecordItemProps>(({
  item, onHomePage
}) => {
  const { course, type, record_id, group_id } = item;
  const info = { id: group_id, record_id, type };
  const handle = () => {
    let path: string | null = '';
    switch (course) {
      case 1:
        path = getWritingQustionPath({ data: info, noSearch: true });
        break;
      case 2:
        path = getReadingQustionPath({ data: info, noSearch: true });
        break;
      case 3:
        path = getListeninQustionPath({ data: info, noSearch: true });
        break;
    }
    path && window.open(path);
  };
  return <div className={classNames(lessModule.itemWrapper, onHomePage && lessModule.onHomePage)} onClick={handle}>
    <div className={lessModule.title}>{item.title}</div>
    <div className={lessModule.type}>{item.type_text}</div>
    <div><TrainingResult
      status={item.status}
      result={item.result}
      result_type={item.result_type}
      schedule={item.schedule}
      score={String(item.score)}
    ></TrainingResult></div>
    <div className={lessModule.time}>{item.create_time}</div>
    {/* {
      !onHomePage && item.status === 2 && <div className={lessModule.redo}>
        <SimpleButton className={lessModule.button} onClick={(e)=>{
          e.stopPropagation();
          e.preventDefault();
          // window.open(window.location.origin + `/listening/#/listening-qa/${item.paper_id}`);
        }}>{i18n.t('common.dauyan_more_tool_redo')}</SimpleButton>
      </div>
    } */}
  </div>;
});