import qs from 'query-string';
import { useParams } from 'react-router';
import { Rate } from 'antd';
import {
  LockOutlined,
  UnlockOutlined
} from '@ant-design/icons';
import classnames from 'classnames';
import { IBasicQuestion } from '@/api/basic/list';
import store, { useAppSelector } from '@/stores';
import i18n from '@/locales/init';
import { StatusLabel } from '../../HistoryList/components/StatusLabel';
import { TrainingResult } from '../TraningResult';

import style from './index.module.less';
interface IBasicQuestionProps {
  data: IBasicQuestion;
}

const generateSearch = (data: any, next_id?: boolean) => {
  const keyValues = [];
  keyValues.push(['record_id', next_id ? 0 : data.record_id]);
  keyValues.push(['question_id', next_id ? data.next_question_id : data.question_id]);

  const selected = store.getState().basicTraingList.selected;
  const payload: any = {};
  selected.forEach((item: any) => {
    payload[item.key] = item.value;
  });
  const queryStr = qs.stringify(payload);

  const search = `${keyValues.length > 0 ? '&' : '?'}search=${btoa(unescape(encodeURIComponent(queryStr)))}`;
  return (keyValues.length > 0 ? `?${keyValues.map((ele) => {
    return `${ele[0]}=${ele[1]}`;
  }).join('&')}` : '') + search;
};

/**
 * 获取阅读题目的路径
 * @param data 
 * @param type 
 * @returns 
 */
export const getReadingQustionPath = (payload: { data: any; noSearch?: boolean; next_question_id?: boolean; }): string | null => {
  let p = '';
  switch (payload.data.type) {
    case 1: {
      p = 'pos';
      break;
    }
    case 2: {
      p = 'sentence';
      break;
    }
    case 3: {
      p = 'translate';
      break;
    }
    case 4: {
      p = 'scan';
      break;
    }
    default:
      return '';
  }

  const query = generateSearch(payload.data, payload.next_question_id);
  const path = `${window.location.origin}/basic-training/#/reading/${p}/${payload.data.id}${query}`;
  return payload.data.is_locked === 1 ? null : path;
};

export const getWritingQustionPath = (payload: { data: any; noSearch?: boolean; next_question_id?: boolean; }): string | null => {
  let p = '';
  switch (payload.data.type) {
    case 1: {
      p = 'conjunction';
      break;
    }
    case 2: {
      p = 'translate';
      break;
    }
    case 3: {
      p = 'grammar';
      break;
    }
    case 4: {
      p = 'merge';
      break;
    }
    case 5: {
      p = 'rewrite';
      break;
    }
    default:
      return '';
  }
  const query = payload.noSearch ? `?record_id=${payload.data.record_id}` : generateSearch(payload.data, payload.next_question_id);
  const path = `${window.location.origin}/basic-training/#/writing/${p}/${payload.data.id}${query}`;
  return path;
};

export const getListeninQustionPath = (payload: { data: any; noSearch?: boolean; next_question_id?: boolean; }): string | null => {
  const query = payload.noSearch ? `?record_id=${payload.data.record_id}` : generateSearch(payload.data, payload.next_question_id);
  const path = `${window.location.origin}/basic-training/#/listening/blank/${payload.data.id}${query}`;
  return path;
};

const statusLabels = [i18n.t('common.dauyan_not_start'), i18n.t('common.dauyan_not_done'), i18n.t('common.dauyan_some_complete')];
const buttonLables = [i18n.t('答题'), i18n.t('继续答题'), i18n.t('重做')];

export const BasicQuestion = (props: IBasicQuestionProps) => {
  const state = useAppSelector(store => store.basicTraingList);
  const { fields } = state;
  const { data } = props;
  const status = data.record_status;
  const statusLabel = statusLabels[status] || status.toString();
  const params = useParams();
  const trainingType = Number(params.type);
  const handleClick = () => {
    switch (trainingType) {
      case 1: {
        const path = getWritingQustionPath({ data });
        path && window.open(path);
        break;
      }
      case 2: {
        const path = getReadingQustionPath({ data });
        path && window.open(path);
        break;
      }
      case 3: {
        const path = getListeninQustionPath({ data });
        path && window.open(path);
        break;
      }
      default: {
        console.error('Unhandled question type', trainingType);
      }
    }
  };

  const fs = fields;

  return (
    <div className={style.wrapper} onClick={handleClick} style={{
      gridTemplateColumns: `repeat(${fs.length + 1}, 1fr)`
    }}>
      {
        fs.map((field, index) => {
          const da = (data as any);
          let value: any = da[field + '_text'] || da[field];

          switch (field) {
            case 'record_status': {
              return <div key={index} className={style.status}><StatusLabel text={statusLabel} status={status}></StatusLabel></div>;
            }
            case 'record_time': {
              if (value === '1970-01-01 08:00' || value === '') {
                value = '-';
              }
              return <div key={index} className={style.time}>{value}</div>;
            }
            case 'result': {
              value = <TrainingResult result={da.result} result_type={da.result_type}
                status={status} schedule={da.schedule} score={da.score} />;
              return <div className={style[field]} key={index}>{value}</div>;
            }
            case 'grammar': {
              value = da.grammar_text;
              return <div className={style[field]} key={index}>{value}</div>;
            }
            case 'score': {
              return (
                <div className={style[field]} key={index}>
                  {value === 0 && da?.is_correct === 2 ? <div style={{ width: 132 }}>-</div> : <Rate disabled value={value} />}
                </div>
              );
            }
            case 'is_locked': {
              let locked_btn = null;
              switch (value) {
                case 1: {
                  locked_btn = <div className={style.locked_01}>
                    <LockOutlined />
                    {i18n.t('未解锁')}
                  </div>;
                  break;
                }
                case 2: {
                  locked_btn = <div className={style.locked_02}>
                    <UnlockOutlined />
                    {i18n.t('已解锁')}
                  </div>;
                  break;
                }
                case 3: {
                  locked_btn = <div className={style.locked_03}>{i18n.t('已完成')}</div>;
                  break;
                }
              }
              return <div className={classnames(style[field], style.field_is_locked)} key={index}>{locked_btn}</div>;
            }
            default: {
              return <div className={style[field]} key={index}>{value}</div>;
            }
          }
        })
      }
      <div className={style.button} onClick={(ev) => {
        // 扫读的特殊体型
        if (data.is_locked && data.record_status === 2) {
          ev.stopPropagation();
          ev.preventDefault();
          const path = getReadingQustionPath({ data, next_question_id: true });
          path && window.open(path);
          return;
        }


        // 需要重做的题型
        if (data.record_status === 2) {
          ev.stopPropagation();
          ev.preventDefault();

          switch (trainingType) {
            case 1: {
              const path = getWritingQustionPath({ data, next_question_id: true });
              path && window.open(path);
              break;
            }
            case 2: {
              const path = getReadingQustionPath({ data, next_question_id: true });
              path && window.open(path);
              break;
            }
            case 3: {
              const path = getListeninQustionPath({ data, next_question_id: true });
              path && window.open(path);
              break;
            }
            default: {
              console.error('Unhandled question type', trainingType);
            }
          }
          return;
        }

      }}>
        <div className={classnames(style.doButton, data.is_locked === 1 && style.doButtonDisable)}>{buttonLables[data.record_status] || buttonLables[0]}</div>
      </div>
    </div>
  );
};

