import { createAsyncThunk, createListenerMiddleware, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import jsCookie from 'js-cookie';
import { DEFAULT_SCORE } from '@/utils/const';
import { setUserName } from '@/utils/logger/arms-config';
import { authCookieDomain } from '@/utils/env';
import { getUserInfoApi, IUserInfoApiResData, updateUserInfoApi, getPermissions, IPermissionList, checkToken } from '@/api/user';
import { getMenuList } from '@/api/common';
import i18n from '@/locales/init';

const defaultAvatarUrl = 'https://duy-ielts-public.oss-cn-hangzhou.aliyuncs.com/front/robot.png';

export enum ExamModeEnum {
  machine = 1, // 机考
  written = 2 // 笔试
}

export interface IUserSubPermission {
  isVip: boolean;
  vipDays: number; // 剩余时间
  vipLevel: number; // 会员等级
  vipText: string; // 会员等级文案
  expirationTime: number; // 截止时间戳
}

export interface IUserIeltsSettings {
  targetScore: {
    targetScore: number;
    listening: number;
    reading: number;
    speaking: number;
    writing: number;
  };
  examTime: number;
  examPurpose: number;
  examType: ExamModeEnum;
  examHistory: 0 | 1 | 2; // 是否参加过雅思 1 是 2 否
}

export interface IUserPermissionInfo {
  isGuide: boolean; // 是否经过新手引导 1是 2 否
  isTourist: boolean; // 有token的游客
  isLogin: boolean; // 已经登录
  role?: string; // role?: 'student' | 'tourist' | 'teacher';
  expirationTime: number;
  isVip: boolean;
  ai?: {
    isVip: boolean;
    freeTime: number;
  };
  writing?: IUserSubPermission;
  reading?: IUserSubPermission;
  listening?: IUserSubPermission;
  isTeacher?: boolean;
  // speaking?: IUserSubPermission;
}

export interface IUserMeta {
  avatar: string; // 头像
  mobile?: string; // 手机号
  name?: string; // 用户名
  // wechatId?: string;
  wechatName?: string; // 微信名
  countryId?: number;
  account?: string;
}

export interface IBreadCrumb {
  title?: string;
  href?: string;
}

export interface IUserStateType {
  userInfoInital: boolean; // 是否尝试请求过用户信息
  permissions: IPermissionList | null;
  init: boolean;

  userMeta: IUserMeta;
  userPermission: IUserPermissionInfo;
  tokenValid?: boolean;
  menu: any[];
  flattenMenu: any[];
  breadcrumbs: IBreadCrumb[];
  userSettings: IUserIeltsSettings;
}

const initialState: IUserStateType = {
  userInfoInital: false,
  init: true,
  permissions: null,
  tokenValid: false,

  userMeta: {
    avatar: defaultAvatarUrl,
  },
  userPermission: {
    isTourist: false,
    isLogin: false,
    isGuide: false,
    isVip: false,
    expirationTime: 0,
  },
  menu: [],
  flattenMenu: [],
  breadcrumbs: [],
  userSettings: {
    targetScore: {
      targetScore: DEFAULT_SCORE,
      listening: DEFAULT_SCORE,
      writing: DEFAULT_SCORE,
      reading: DEFAULT_SCORE,
      speaking: DEFAULT_SCORE,
    },
    examType: ExamModeEnum.machine,
    examTime: 0,
    examPurpose: 0,
    examHistory: 0,

  },
};

function updateUserInfo(data: Partial<IUserInfoApiResData>) {
  updateUserInfoApi(data).then(res => {
    toast.success(i18n.t('common.dauyan_update_successful'));
  }).catch(e => {
    toast.error(e.msg ?? i18n.t('common.dauyan_update_failed'));
  });
}


export const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async (
  _, { getState, rejectWithValue }
) => {
  try {
    const res = await getUserInfoApi();
    if (res.code !== 200) {
      rejectWithValue(null);
      return;
    }

    const role = res.header ? res.header['x-user-role'] : undefined;

    const writingVip = res.data.expiration_time > Date.now() / 1000;
    const readingVip = res.data.reading_expiration_time > Date.now() / 1000;
    const listeningVip = res.data.listening_expiration_time > Date.now() / 1000;

    return {
      examType: res.data.exam_type,
      init: res.data.target_score > 0,

      userMeta: {
        avatar: res.data.head_sculpture || defaultAvatarUrl,
        name: res.data.name,
        mobile: res.data.mobile,
        wechatName: res.data.wechat_name,
        countryId: res.data.country_id,
        account: res.data.account,
      },
      freeTime: res.data.ai_test_num,
      userPermission: {
        isLogin: !!role && role !== 'tourist',
        isGuide: res.data.is_guide === 1,
        isTourist: role === 'tourist',
        role,
        expirationTime: Math.max(res.data.expiration_time, res.data.reading_expiration_time, res.data.listening_expiration_time),
        isVip: writingVip || readingVip || listeningVip,
        writing: {
          isVip: writingVip,
          vipDays: res.data.vip_days,
          vipLevel: res.data.vip_level,
          vipText: res.data.vip_text,
          expirationTime: res.data.expiration_time,
        },
        reading: {
          isVip: readingVip,
          vipDays: res.data.reading_vip_days,
          vipLevel: res.data.reading_vip_level,
          vipText: '',
          expirationTime: res.data.reading_expiration_time,
        },
        listening: {
          isVip: listeningVip,
          vipDays: res.data.listening_vip_days,
          vipLevel: 0,
          vipText: '',
          expirationTime: res.data.listening_expiration_time,
        },
      },
      userSettings: {
        targetScore: {
          targetScore: res.data.target_score,
          listening: res.data.target_score_listen,
          reading: res.data.target_score_read,
          speaking: res.data.target_score_speak,
          writing: res.data.target_score_write,
        },
        examTime: res.data.exam_date,
        examPurpose: res.data.exam_purpose,
        examType: res.data.exam_type,
        examHistory: res.data.is_ielts,
      },
    };
  } catch (e) {
    console.error('fetchUserInfo error', e);
  }
});

export const fetchTouristInfo = createAsyncThunk('user/fetchTouristInfo', async (
  _, { getState, rejectWithValue }
) => {
  const token = jsCookie.get('token');

  if (!token) {
    try {
      const res = await checkToken();
      if (res.code !== 200) {
        rejectWithValue(null);
        return;
      }
      jsCookie.set('token', res.data.token, { domain: authCookieDomain });
      window.location.reload();
      return {
        data: res.data
      };
    } catch (e) {
      console.error('fetchTouristInfo error', e);
    }
  }
  return;
});

export const fetchUserPermissions = createAsyncThunk('user/fetchUserPermissions', async (
  _, { getState, rejectWithValue }
) => {
  try {
    const res = await getPermissions();
    if (res.code !== 200) {
      rejectWithValue(null);
      return;
    }
    return {
      permissions: res.data
    };
  } catch (e) {
    console.error('fetchUserInfo error', e);
  }
});

export const fetchUserMenus = createAsyncThunk('user/fetchUserMenus', async (_, { getState, rejectWithValue }) => {
  try {
    const res = await getMenuList();
    if (res.code !== 200) {
      rejectWithValue(null);
      return;
    }
    return {
      menu: res.data.list
    };
  } catch (e) {
    console.error('fetchUserMenus error', e);
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserInfo: (state) => {
      state.userInfoInital = false;
      state.userMeta = {
        avatar: defaultAvatarUrl,
      };
    },
    setTargetScore: (state, action: { payload: number; }) => {
      updateUserInfo({ target_score: action.payload, target_score_listen: action.payload, target_score_read: action.payload, target_score_speak: action.payload, target_score_write: action.payload });
      state.userSettings.targetScore.targetScore = action.payload;
      state.userSettings.targetScore.reading = action.payload;
      state.userSettings.targetScore.listening = action.payload;
      state.userSettings.targetScore.writing = action.payload;
      state.userSettings.targetScore.speaking = action.payload;
    },
    setExamMode: (state, action: { payload: number; }) => {
      updateUserInfo({ exam_type: action.payload });
      if (state.userSettings) {
        state.userSettings.examType = action.payload;
      }
    },
    reduceAIFreeTryTimes: (state) => {
      if (state.userPermission.ai?.isVip) {
        return;
      } else if (typeof state.userPermission.ai?.freeTime === 'number' && state.userPermission.ai?.freeTime > 0) {
        state.userPermission.ai.freeTime -= 1;

      }
    },
    setTokenAbmormal: (state) => {
      state.tokenValid = false;
    },

    setBreadCrumbs: (state, action: { payload: IBreadCrumb[]; }) => {
      state.breadcrumbs = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        if (action.payload) {
          state.init = action.payload.init;
          state.userMeta = action.payload.userMeta;
          state.userPermission = action.payload.userPermission;
          state.userInfoInital = true;
          state.tokenValid = true;
          state.userPermission.ai = {
            isVip: action.payload.userPermission.listening.isVip || action.payload.userPermission.reading.isVip || action.payload.userPermission.writing.isVip,
            freeTime: action.payload.freeTime,
          };
          state.userSettings = action.payload.userSettings;
        }
      })
      .addCase(fetchUserPermissions.fulfilled, (state, action) => {
        if (action.payload) {
          state.permissions = action.payload.permissions;
        }
      })
      .addCase(fetchUserMenus.fulfilled, (state, action) => {
        if (action.payload) {
          const list: any[] = [];
          const menus = action.payload.menu || [];
          const flattenMenu = (menus: any[]) => {
            menus.forEach(item => {
              list.push(item);
              if (item.children) {
                flattenMenu(item.children);
              }
            });
          };
          flattenMenu(menus);
          state.menu = action.payload.menu;
          state.flattenMenu = list;
        }
      })
      .addCase(fetchTouristInfo.fulfilled, (state, action) => {
        if (action.payload && action.payload.data.token) {
          state.userPermission.isTourist = true;
        }
      });
  },
});

export const userMiddleware = createListenerMiddleware();
userMiddleware.startListening({
  predicate: (action, nextState, currentState) => {
    return (currentState as any).user.userMeta.mobile !== (nextState as any).user.userMeta.mobile && typeof (nextState as any).user.userMeta.mobile === 'string' && !!(nextState as any).user.userMeta.mobile;
  },
  effect: (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const state = listenerApi.getState();
    setUserName((state as any).user.userMeta.mobile);
  },

});

export const { setTargetScore, setExamMode, resetUserInfo, reduceAIFreeTryTimes, setTokenAbmormal, setBreadCrumbs } = userSlice.actions;

export default userSlice;
