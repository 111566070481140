import { useEffect } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { ConfigProvider, Pagination } from 'antd';
import zh_CN from 'antd/locale/zh_CN';
import { SimpleSpin } from '@/components/Simple/SimpleSpin';
import { useAppDispatch, useAppSelector } from '@/stores';
import { basicTraingList, getQuestionListAsync, getSearchConfigAsync } from '@/stores/slices/basicTrainingListSlice';
import { getLanguage } from '@/locales/init';
import { useDocumentVisible } from '@/hooks/useDocumentVisible';
import { SeachConfig } from './SearchConfig';
import style from './index.module.less';
import { BasicQuestion } from './BasicQuestion';
import { TrainingListHead } from './TrainingListHead';

export const TrainingList = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const state = useAppSelector(store => store.basicTraingList);
  const { selected, doFetchConfig, fetchingList, list, total, page, page_size, type, doFetchList, reloadState } = state;
  useEffect(() => {
    dispatch(basicTraingList.actions.setType(Number(params.type)));
  }, [params.type]);
  useEffect(() => {
    if (type > 0 || doFetchConfig > 0) {
      dispatch(getSearchConfigAsync());
    }
  }, [doFetchConfig, type]);

  useEffect(() => {
    if (type > 0 && selected.length > 0 && doFetchList > 0) {
      dispatch(getQuestionListAsync());
    }
  }, [page, page_size, doFetchList, type]);
  useEffect(() => {
    if (reloadState > 0) {
      dispatch(getQuestionListAsync());
    }
  }, [reloadState]);
  useDocumentVisible(() => {
    dispatch(basicTraingList.actions.reload());
  });

  const onShowSizeChange = (current: number, pageSize: number) => {
    dispatch(basicTraingList.actions.setPageSize(pageSize));
  };
  const setPage = (page: number) => {
    dispatch(basicTraingList.actions.setPage(page));
  };

  return <div className={style.listContainer}>
    <SeachConfig></SeachConfig>
    <TrainingListHead />
    <div className={classNames(style.basicQuestionList, 'scroll-bar-visible')}>
      <SimpleSpin spinning={fetchingList}>
        {
          list.map((item, index) => <BasicQuestion key={index} data={item} ></BasicQuestion>)
        }
      </SimpleSpin>
    </div>
    <div className={style.paginationContainer}>
      <ConfigProvider locale={getLanguage() === 'zh-CN' ? zh_CN : undefined}>
        <Pagination
          className={style.pagination}
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          onChange={setPage}
          defaultCurrent={page}
          current={page}
          pageSize={page_size}
          total={total}
          showQuickJumper
        />
      </ConfigProvider>
    </div>
  </div>;
};