import { Select } from 'antd';
import { useAppDispatch, useAppSelector } from '@/stores';
import { basicTraingList } from '@/stores/slices/basicTrainingListSlice';
import style from './index.module.less';

export const SeachConfig = () => {
  const config = useAppSelector(store => store.basicTraingList.config);
  const selected = useAppSelector(store => store.basicTraingList.selected);
  const dispatch = useAppDispatch();
  const handleSelected = (key: string, value: number) => {
    dispatch(basicTraingList.actions.setSelected({ key, value }));
  };
  return <div className={style.configContainer}>
    {config.map(item => {
      const value = selected.find(v => v.key === item.key);
      const lable = item.list.find(l => l.value === value?.value);

      return <div key={item.key} className={style.configList}>
        <div className={style.itemTitle}>{item.name}</div>
        <div className={style.itemList}>
          <Select className={style.select} value={lable?.name} listHeight={200} defaultValue={lable?.name} onSelect={(value) => {
            handleSelected(item.key, Number(value));
          }}>
            {
              item.list.map(child => {
                return <Select.Option key={child.value} value={child.value}>{child.name}</Select.Option>;
              })
            }
          </Select>
        </div>
      </div>;
    })}
  </div>;
};