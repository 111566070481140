import { Rate } from 'antd';
import i18n from '@/locales/init';
import style from './index.module.less';
export const TrainingResult = (props: { status: number; result: number; result_type: number; schedule: string; score: string; }) => {
  const { result, result_type, status, schedule, score } = props;
  if (status !== 2) {
    return '-';
  }
  return <div className={style.result}>
    {
      result_type === 1 && <>
        {
          result === 1 ? <>{i18n.t('正确')}<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 19.25C14.8325 19.25 18.75 15.3325 18.75 10.5C18.75 5.66751 14.8325 1.75 10 1.75C5.16751 1.75 1.25 5.66751 1.25 10.5C1.25 15.3325 5.16751 19.25 10 19.25ZM5.625 10.7581L6.50813 9.875L8.75 12.1163L13.4906 7.375L14.375 8.25937L8.75 13.8837L5.625 10.7581Z" fill="#2EB4A4" />
          </svg>
          </> : <><div className={style.wrong}>{i18n.t('错误')}</div><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.75 10.5C18.75 5.66751 14.8325 1.75 10 1.75C5.16751 1.75 1.25 5.66751 1.25 10.5C1.25 15.3325 5.16751 19.25 10 19.25C14.8325 19.25 18.75 15.3325 18.75 10.5ZM7.08863 6.68442L10 9.61344L12.9114 6.68442L13.7979 7.56563L10.8812 10.5L13.7979 13.4344L12.9114 14.3156L10 11.3866L7.08864 14.3156L6.2021 13.4344L9.11879 10.5L6.20208 7.56563L7.08863 6.68442Z" fill="#FF4040" />
          </svg></>
        }
      </>
    }
    {
      result_type === 2 && <div className={result === 4 ? style.passed : ''}>{<Rate disabled value={Number(score)} />}</div>
    }
    {
      result_type === 3 && <div>{schedule}</div>
    }
  </div>;
};
