import i18n from '@/locales/init';
import { useAppSelector } from '@/stores';
import style from './index.module.less';

export const TrainingListHead = () => {
  const state = useAppSelector(store => store.basicTraingList);
  const { fields, fields_name_map } = state;
  const fs = fields;

  return <div className={style.wrapper} style={{
    gridTemplateColumns: `repeat(${fs.length + 1}, 1fr)`
  }}>
    {
      fs.map((field, index) => <div key={index}>{(fields_name_map || {})[field] || ''}</div>)
    }
    <div>{i18n.t('')}</div>
  </div>;
};